import { getCredentialRequest } from '../../../services/vidcredentials';
import React, { useContext, useEffect, useState } from 'react';
import { CredentialRequestsListView } from '../../../domain/credentialView';
import eyeSelectedIcon from '../../../assets/eyeSelectedIcon.png';
import MaterialTable from 'material-table';
import { CredentialRequestDetailsModal } from '../CredentialRequestDetailsModal/CredentialRequestDetailsModal';
import TooltipItem from '../../../components/ToolTipItem/ToolTipItem';
import { CredentialRequestDetailsView } from '../../../domain/credentialRequestView';
import CustomPagination from '../../../components/TablePagination/CustomPagination';
import { CredentialRequestsContext } from '../../../contexts/CredentialsProviders/Providers/CredentialRequestsContext';
import { Spinner } from '../../../components/Spinner/Spinner';
import { formatDate } from '../../Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { useTranslation } from 'react-i18next';

export function CredentialRequestsTable() {
  const { t } = useTranslation();
  const [isCredentialDetailsModalOpen, setCredentialDetailsModalOpen] =
    useState(false);
  const { loadCredentials, credentials, tableSpecs, isLoading } = useContext(
    CredentialRequestsContext,
  );

  const [credential, setCredential] = useState(
    {} as CredentialRequestDetailsView,
  );

  useEffect(() => {
    if (!isCredentialDetailsModalOpen && credential.id) loadCredentials(10, 1);
  }, [isCredentialDetailsModalOpen]);

  const openCredential = async (id: string, e: any) => {
    e.preventDefault(); //Prevent the default href=# behavoir
    try {
      const credential = await getCredentialRequest(id);
      setCredential(credential as CredentialRequestDetailsView);
      setCredentialDetailsModalOpen(true);
    } catch (error) {
      console.log('Error unable to get the credentialDetails');
      return;
    }
  };

  const tableColumns = [
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: t('filters.credentialType'),
      field: 'did',
      render: (data: CredentialRequestsListView) => {
        return (
          <>
            {data?.types.map((element, i) => {
              return `${element}${i === data.types.length - 1 ? '' : ', '}`;
            })}
          </>
        );
      },
    },
    {
      title: t('table.creationDate'),
      field: 'requestedAt',
      render: (data: CredentialRequestsListView) =>
        formatDate(data.requestedAt),
    },
    {
      title: t('table.status'),
      field: 'status',
      render: (data: CredentialRequestsListView) =>
        t(`requestStatus.${data.status}`),
    },
    {
      title: '',
      field: '',
      render: (data: CredentialRequestsListView) => {
        return (
          <button
            className="imageButton"
            onClick={(e) => openCredential(data.id, e)}
          >
            <TooltipItem id={'detail' + data.id} title={t('table.viewDetails')}>
              <img src={eyeSelectedIcon} className="eyeIcon" alt="Eye icon" />
            </TooltipItem>
          </button>
        );
      },
    },
  ];

  return (
    <div>
      <CredentialRequestDetailsModal
        isOpen={isCredentialDetailsModalOpen}
        setIsOpen={setCredentialDetailsModalOpen}
        credential={credential}
      />
      <MaterialTable
        key={tableSpecs.tableSize}
        columns={tableColumns}
        isLoading={isLoading}
        data={credentials}
        title={''}
        options={{
          selection: false,
          search: false,
          pageSize: tableSpecs.tableSize,
          headerStyle: {
            background: '#F9F9FB 0% 0% no-repeat padding-box',
            fontFamily: 'TT-Norms-Black',
          },
          maxBodyHeight: screen.height * 0.6,
          emptyRowsWhenPaging: false,
        }}
        components={{
          OverlayLoading: () => (
            <div
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </div>
          ),
          Pagination: () => (
            <CustomPagination
              loadCredentials={loadCredentials}
              tableSpecs={tableSpecs}
            />
          ),
        }}
      />
    </div>
  );
}
