import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { CredentialIssuedDetailsView } from '../../../../domain/credentialView';
import '../CredentialDetailsModal.css';
import closeIcon from '../../../../assets/closeIcon.png';
import {
  ParsedBackendError,
  revokeVerifiableCredential,
} from '../../../../services/vidcredentials';
import { Popup } from '../../../../components/Popup/Popup';
import { formatDate, transformCamelCase } from '../helpers/StringHelpers';
import { renderChildren } from '../helpers/RenderFunctions';
import { useTranslation } from 'react-i18next';

interface Props {
  credential: CredentialIssuedDetailsView;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const CredentialIssuedModal = (props: Props) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [wantsToRemove, setWantsToRemove] = useState(false);
  const [requestedRevocation, setRequestedRevocation] = useState(false);

  const closeModal = (e: any) => {
    e.preventDefault();
    props.setIsOpen(false);
  };

  const revokeCredential = async () => {
    try {
      await revokeVerifiableCredential(props.credential.id);
      // TODO: Reload Table?
      setRequestedRevocation(true);
      setSuccessMessage(t('details.revokeSuccess'));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage((error as ParsedBackendError).title);
      setSuccessMessage('');
    }

    setWantsToRemove(false);
  };

  return (
    <Modal isOpen={props.isOpen} centered={true} fullscreen="lg" size="lg">
      <ModalHeader
        close={
          <a href="#" onClick={(e) => closeModal(e)}>
            <img alt="Close icon" src={closeIcon} className="iconClose" />
          </a>
        }
        toggle={() => props.setIsOpen(false)}
        className="modalHeader"
      >
        {t('details.credentialDetails')}
      </ModalHeader>
      <ModalBody className="modalBody">
        <table className="tableModal">
          <thead>
            <tr>
              <td style={{ backgroundColor: 'white' }}>&nbsp;</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th scope="row">{t('details.credentialIssuanceId')}:</th>
              <td data-testid="issuanceId">{props.credential.issuanceId}</td>
            </tr>
            <tr>
              <th scope="row">{t('details.type')}:</th>
              <td data-testid="types">{props.credential.types}</td>
            </tr>
            <tr>
              <th scope="row">{t('details.issuer')}:</th>
              <td>{props.credential.issuer}</td>
            </tr>
            <tr>
              <th scope="row">{t('details.holder')}:</th>
              <td data-testid="holder">
                {props.credential.credentialOwnerDid}
              </td>
            </tr>
            <tr>
              <th scope="row">{t('table.status')}:</th>
              <td data-testid="status">
                {t(`status.${props.credential.status}`)}
              </td>
            </tr>
            <tr>
              <th scope="row">{t('table.expirationDate')}:</th>
              <td data-testid="expirationDate">
                {formatDate(props.credential.expirationDate)}
              </td>
            </tr>
            <tr>
              <th scope="row">{t('details.createdAt')}:</th>
              <td data-testid="createdAt">
                {formatDate(props.credential.createdAt)}
              </td>
            </tr>
            <tr>
              <td style={{ backgroundColor: 'white' }}>&nbsp;</td>
            </tr>
          </tbody>
        </table>
        {props.credential.verifiableCredential &&
          Object.entries(props.credential.verifiableCredential).map(
            ([key, value]) => {
              return (
                <>
                  <h2 className="attributeHeader">{transformCamelCase(key)}</h2>
                  {renderChildren(value, {})}
                  <tr>&nbsp;</tr>
                </>
              );
            },
          )}
      </ModalBody>
      <ModalFooter>
        <div className="buttons-container">
          {props.credential.status === 'ISSUED' && !requestedRevocation && (
            <Button
              className="buttonRevoke"
              onClick={() => setWantsToRemove(true)}
            >
              {t('actions.revoke')}
            </Button>
          )}
        </div>
        {errorMessage && (
          <Popup
            error
            message={errorMessage}
            onClose={() => setErrorMessage('')}
          />
        )}
        {successMessage && (
          <Popup
            success
            message={successMessage}
            onClose={() => setSuccessMessage('')}
          />
        )}
        {wantsToRemove && (
          <Popup
            confirmation
            message={t('table.sureRevoke')}
            onConfirm={() => revokeCredential()}
            onReject={() => setWantsToRemove(false)}
            onClose={() => setWantsToRemove(false)}
          />
        )}
      </ModalFooter>
    </Modal>
  );
};
