import axios from 'axios';
import { FrontendErrors } from '../exceptions/frontendErrors';
import { BACKEND_URL, BACKEND_URL_BASE, OPENID, PATHS } from '../config';
import {
  CreateCredentialOrderDto,
  CreateUserCredentialOrderDto,
} from '../domain/createCredentialOrderDto';
import {
  CredentialDetailsView,
  CredentialOrderDetailsView,
  CredentialRequestDto,
} from '../domain/credentialView';
import i18n from './../i18n/i18n';
import { RefreshTokenRequestDto } from '../domain/refreshToken';
import axiosInstance from './axiosInstance';
import { AuditTrailDetail } from '../domain/auditTrails';
import { Device } from '../domain/devices';
import { CredentialRequestDetailsView } from '../domain/credentialRequestView';
import { CredentialTypeDto } from '../domain/credentialTypes';
import { CredentialsContextTypes } from '../contexts/CredentialsProviders/CredentialsContext';

export interface BackendError {
  statusCode: number;
  internalCode: string;
  message: string;
  timestamp: string;
}

export interface ParsedBackendError {
  title: string;
  details?: string;
}

export interface BackendResponse {
  error: ParsedBackendError;
}

export interface LoadCredentialResponse<CredentialsType> {
  credentialsList: CredentialsType[];
  auditTrailsList?: CredentialsType[];
  totalNumber: number;
  firstPage: string;
  nextPage: string;
  previousPage: string;
  lastPage: string;
  tableSize: number;
  currentPage: number;
}

function translateBackendError(error: BackendError): ParsedBackendError {
  return {
    title: i18n.t(error.internalCode),
    details: error.message.split(']')[1],
  };
}

async function authLogin(code: string) {
  try {
    const body = {
      code: code,
      redirectUri: OPENID.REDIRECT_CALLBACK_LOGIN,
    };
    const response = await axiosInstance.post(
      `${BACKEND_URL}${PATHS.LOGIN}`,
      body,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.LOGIN_AUTH} ${response.data}.`);
    }
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return { error: parsedError };
    }
    return { error: { title: 'Unknown' } };
  }
}

async function refreshToken() {
  try {
    const body: RefreshTokenRequestDto = {
      grant_type: 'refresh_token',
    };

    const response = await axiosInstance.post(
      `${BACKEND_URL}${PATHS.TOKEN}`,
      body,
    );

    return response.data;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function logout() {
  try {
    await axiosInstance.delete(`${BACKEND_URL}${PATHS.TOKEN}`);

    return true;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

function createCredentialOrder(
  credentialOrder: CreateCredentialOrderDto,
  language: string,
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.post(
        `${BACKEND_URL}${PATHS.CREDENTIAL_ORDER}?language=${language}`,
        credentialOrder,
      );
      if (response.status === 200 || response.status === 201) {
        return resolve(response.data);
      }
      if (axios.isAxiosError(response)) {
        const parsedError = translateBackendError(
          response.response?.data as BackendError,
        );
        reject(parsedError);
      }
      reject({ title: 'Unknown' });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const parsedError = translateBackendError(
          error.response?.data as BackendError,
        );
        reject(parsedError);
      }
      reject({ title: 'Unknown' });
    }
  });
}

function createUserCredentialOrder(
  userCredentialOrder: CreateUserCredentialOrderDto,
  language: string,
) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await axiosInstance.post(
        `${BACKEND_URL}${PATHS.USER_CREATE_CREDENTIAL}?language=${language}`,
        userCredentialOrder,
      );
      if (response.status === 200 || response.status === 201) {
        return resolve(response.data);
      }
      if (axios.isAxiosError(response)) {
        const parsedError = translateBackendError(
          response.response?.data as BackendError,
        );
        reject(parsedError);
      }
      reject({ title: 'Unknown' });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const parsedError = translateBackendError(
          error.response?.data as BackendError,
        );
        reject(parsedError);
      }
      reject({ title: 'Unknown' });
    }
  });
}

async function issueCredential(
  credentialOrderId: string,
  code: string,
): Promise<{ data: any } | BackendResponse> {
  try {
    const body = { code: code, redirectUri: OPENID.REDIRECT_CALLBACK };
    const url = `${BACKEND_URL}${PATHS.ISSUE_CREDENTIAL}/${credentialOrderId}`;
    const response = await axios.post(url, body);
    return { data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return { error: parsedError };
    }
    return { error: { title: 'Unknown' } };
  }
}
async function updateCredentialRequest(
  credentialOrderId: string,
  body: CredentialRequestDto,
): Promise<{ data: any } | BackendResponse> {
  try {
    const url = `${BACKEND_URL}${PATHS.CREDENTIAL_REQUESTS}/${credentialOrderId}`;
    const response = await axiosInstance.put(url, body);
    return { data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return { error: parsedError };
    }
    return { error: { title: 'Unknown' } };
  }
}

async function rejectVerifiableCredential(credentialOrderId: string) {
  try {
    const url = `${BACKEND_URL}${PATHS.CREDENTIAL_REQUESTS}/${credentialOrderId}`;
    const response = await axiosInstance.delete(url);
    return { data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return { error: parsedError };
    }
    return { error: { title: 'Unknown' } };
  }
}

async function revokeVerifiableCredential(credentialId: string) {
  try {
    const url = `${BACKEND_URL}/revocation/${credentialId}`;
    const response = await axiosInstance.post(url);
    return { data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return { error: parsedError };
    }
    return { error: { title: 'Unknown' } };
  }
}

async function revokeUserVerifiableCredential(revocationPath: string) {
  try {
    const url = `${BACKEND_URL_BASE}${revocationPath}`;
    const response = await axiosInstance.post(url);
    return { data: response.data };
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return { error: parsedError };
    }
    return { error: { title: 'Unknown' } };
  }
}

async function getCredentialTypes(): Promise<
  CredentialTypeDto[] | BackendResponse
> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.CREDENTIAL_TYPES}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.CREDENTIAL_TYPES} ${response.data}.`);
    }
    return response.data;
  } catch (error) {
    return { error: translateBackendError(error as BackendError) };
  }
}

function selectEndpoint(type: CredentialsContextTypes | undefined) {
  if (type === CredentialsContextTypes.USER_CREDENTIALS)
    return PATHS.USER_CREDENTIALS;
  if (type === CredentialsContextTypes.CREDENTIAL_REQUESTS)
    return PATHS.CREDENTIAL_REQUESTS;
  if (type === CredentialsContextTypes.AUDIT_TRAILS) return PATHS.AUDIT_TRAILS;
  return PATHS.CREDENTIAL_PROJECTIONS;
}

async function getCredentials(
  tableSize: number,
  currentPage: number,
  filterQuery?: string,
  credentialType?: CredentialsContextTypes,
): Promise<LoadCredentialResponse<any> | BackendResponse> {
  const endpoint = selectEndpoint(credentialType);
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${endpoint}?tableSize=${tableSize}&currentPage=${currentPage}&${
        filterQuery ?? ''
      }`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.CREDENTIAL_LIST} ${response.data}.`);
    }
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const parsedError = translateBackendError(
        error.response?.data as BackendError,
      );
      return { error: parsedError };
    }
    return { error: { title: 'Unknown' } };
  }
}

async function getDevicesList(): Promise<Device[] | ParsedBackendError> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.DEVICE_LIST}`,
    );

    return response.data as Device[];
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function getAuditTrailsDetail(
  eventId: string,
): Promise<AuditTrailDetail | ParsedBackendError> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.AUDIT_TRAILS}/${eventId}`,
    );

    return response.data as AuditTrailDetail;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function getCredentialView(
  detailsPath: string,
): Promise<
  CredentialDetailsView | CredentialOrderDetailsView | ParsedBackendError
> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL_BASE}${detailsPath}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.CREDENTIAL_ORDER} ${response.data}.`);
    }
    return response.data;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function getCredentialRequest(
  credentialId: string,
): Promise<CredentialRequestDetailsView | ParsedBackendError> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.CREDENTIAL_REQUESTS}/${credentialId}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.CREDENTIAL_REQUEST} ${response.data}.`);
    }
    return response.data;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function getUserCredential(
  detailsPath: string,
): Promise<CredentialDetailsView | ParsedBackendError> {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL_BASE}${detailsPath}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.CREDENTIAL_ORDER} ${response.data}.`);
    }
    return response.data as CredentialDetailsView;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function getIvmTypeByCredentialRequestId(credentialId: string) {
  try {
    const response = await axiosInstance.get(
      `${BACKEND_URL}${PATHS.CREDENTIAL_ORDER}/${credentialId}/ivm`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(
        `${FrontendErrors.LEVEL_OF_ASSURANCE_TYPE} ${response.data}.`,
      );
    }
    return response.data;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function resendEmailCredentialOrder(
  resendPath: string,
): Promise<CredentialDetailsView | ParsedBackendError> {
  try {
    const response = await axiosInstance.post(
      `${BACKEND_URL_BASE}${resendPath}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.RESEND_EMAIL} ${response.data}.`);
    }
    return response.data as CredentialDetailsView;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}

async function resendEmailUserCredentialOrder(
  resendPath: string,
): Promise<CredentialDetailsView | ParsedBackendError> {
  try {
    const response = await axiosInstance.post(
      `${BACKEND_URL_BASE}${resendPath}`,
    );
    if (response.status !== 200 && response.status !== 201) {
      throw new Error(`${FrontendErrors.RESEND_EMAIL} ${response.data}.`);
    }
    return response.data as CredentialDetailsView;
  } catch (error) {
    return translateBackendError(error as BackendError);
  }
}
export {
  authLogin,
  createCredentialOrder,
  getCredentials,
  issueCredential,
  rejectVerifiableCredential,
  revokeVerifiableCredential,
  getCredentialTypes,
  getCredentialView,
  getIvmTypeByCredentialRequestId as getLoATypeByCredentialRequestId,
  getUserCredential,
  createUserCredentialOrder,
  refreshToken,
  resendEmailCredentialOrder,
  logout,
  getAuditTrailsDetail,
  updateCredentialRequest,
  getDevicesList,
  revokeUserVerifiableCredential,
  resendEmailUserCredentialOrder,
  getCredentialRequest,
};
