import { supportedLanguages } from '../../i18n/i18n';
import { useTranslation } from 'react-i18next';

export const LanguageSwitch = () => {
  const { i18n } = useTranslation();

  return (
    <select
      className="language-select"
      value={i18n.language}
      onChange={(e) => i18n.changeLanguage(e.target.value)}
    >
      {Object.entries(supportedLanguages).map(
        ([languageCode, languageName]) => (
          <option value={languageCode}>{languageName}</option>
        ),
      )}
    </select>
  );
};
