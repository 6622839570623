export default {
  invalidUserRolException: 'Rol de usuario inválido',
  roleEntityException: 'Rol de usuario inválido',
  roleIssuerException: 'Rol de usuario inválido',
  invalidUuidFormatException: 'Error de formato en uuid',
  invalidISOFormatException: 'Error de formato en la fecha',
  invalidEmailFormatException: 'Error de formato en el email',
  InvalidLevelOfAssuranceException: 'Nivel de garantía no soportado',
  InvalidFieldPathException: 'Ruta de campo inválido',
  InvalidCredentialSubjectValue:
    'La propiedad del asunto de la credencial proporcionada no coincide con la solicitada',
  InvalidCredentialSubjectProperty:
    'No se encuentra la propiedad en el asunto de la credencial',
  DidNotFoundInJwt: 'DID no encontrado en JWT',
  InternalServerCustomError: 'Error interno',
  CredentialIdNotFoundException: 'Orden de credencial no encontrada',
  CredentialOrderIdNotFoundException: 'Orden de credencial no encontrada',
  CredentialOrdersByFilterNotFoundException:
    'No hay credenciales que coincidan con tu búsqueda',
  CredentialOrderNotFoundException:
    'No se ha encontrado ninguna orden de credencial',
  AuthServiceValidationException: 'Acceso de entidad no autorizado',
  CredentialDuplicatedException:
    'La credencial ya ha sido emitida anteriormente',
  CredentialPayloadValidatorException:
    'Fallo validando el payload de la credencial',
  EntityDidNotValidException: 'DID de la entidad inválido',
  frontend_error_000: 'Error autenticando el usuario: ',
  frontend_error_001: 'Error obteniendo la orden de credencial: ',
  frontend_error_002: 'Error obteniendo las credencials',
  frontend_error_003: 'Error al emitir la credencial: ',
  frontend_error_004: 'Error obteniendo los tipos de credenciales: ',
  frontend_error_005: 'Error obteniendo los niveles de garantía: ',
  frontend_error_006: 'Error obteniendo el nivel de garantía: ',
  frontend_error_007: 'Error obteniendo las entidades',
  frontend_error_008: 'Error al reenviar el email',
  login: {
    control:
      'Controla y emite credenciales verificables en cualquier momento y cualquier lugar',
    with_vidchain:
      'Con VIDidentity ofrecemos soluciones de identidad segura que permiten una privacidad, derechos y libertades a las personas de una manera más digital y sostenible.',
    login_with: 'Inicia sesión con VIDwallet',
    more_info: 'Más info sobre VIDwallet',
    successful: 'El inicio de sesión ha sido exitoso',
    failed: 'El inicio de sesión falló',
    redirected: 'Serás redirigido a la pantalla de inicio',
    try_again:
      'Por favor, vuélvelo a probar o contacta con el administrador de tu plataforma.',
    continueIssuance:
      'Haz clic en el botón de inicio de sesión para continuar con la emisión de la credencial',
    redirecting: 'Redirigiendo para la autenticación…',
    get_it: 'Consiguela gratis en tu teléfono',
  },
  callback: {
    success: 'El proceso de emisión de la credencial ha sido exitoso',
    mobile_wallet:
      'Credencial emitida. Abre VIDwallet para recibir la credencial',
    go_wallet: 'Puedes encontrar tu credencial en tu cartera.',
    open_wallet: 'Abrir VIDwallet',
    failed: 'El proceso de emisión de la credencial ha fallado',
    error_details: 'Detalles del error:',
  },
  actions: {
    cancel: 'Cancelar',
    filter: 'Filtrar',
    accept: 'Aceptar',
    success: 'Éxito',
    confirm: 'Confirmar',
    search: 'Buscar',
    create: 'Crear',
    revoke: 'Revocar',
    resend: 'Reenviar',
    edit: 'Editar',
    close: 'Cerrar',
    save: 'Guardar',
    approve: 'Aprobar',
    reject: 'Rechazar',
    sendToSign: 'Enviar a firmar',
    ok: 'OK',
  },
  form: {
    fill: 'Completa el formulario',
    receiverEmail: 'Email del receptor',
    identityVerification: 'Método de verificación de identidad',
    loadingCredential: 'Cargando tipos de credencial…',
    loadingIdentity: 'Cargando datos de verificación de identidad…',
    createNewUser: 'Crear nuevo usuario',
    fillfield: 'Debes llenar este campo',
    provideValidEmail: 'Debes proveer un email válido',
    provideValidNumber: 'Debes proveer un número personal válido',
    provideValidURL: 'Debes proveer una URL válida',
    provideValidDate: 'Debes proveer una fecha válida',
    credentialCreated:
      'Orden de credencial creada con éxito. El usuario recibirá un correo electrónico para obtener la credencial',
    credentialF2FCreated:
      'Orden de credencial creada con éxito. Por favor, firma el documento en el dispositivo %%%%% para finalizar el proceso',
    credentialSubject: 'Asunto de la credencial',
    language: 'Idioma',
    chooseLanguage: 'Selecciona un idioma...',
    credentialUpdated: 'Solicitud de credencial actualizada con éxito',
    userLanguage: 'Idioma del usuario',
  },
  language: {
    spanish: 'Español',
    english: 'Inglés',
  },
  filters: {
    any: 'Cualquier atributo en la credencial',
    credentialType: 'Tipo de credencial',
    startDate: 'Fecha inicial',
    endDate: 'Fecha final',
    actionName: 'Nombre de la acción',
    entityName: 'Nombre de la entidad',
    selectEntity: 'Selecciona una entidad',
    searchText: 'Texto de búsqueda',
    types: 'Tipo',
    status: 'Estado',
  },
  table: {
    creationDate: 'Fecha de creación',
    notAvailable: 'N/A',
    sureRevoke: '¿Estás seguro/a de que quieres revocar esta credencial?',
    sureReject:
      '¿Estás seguro/a de que quieres rechazar esta solicitud de credencial?',
    agentName: 'Nombre de agente',
    date: 'Fecha',
    status: 'Estado',
    viewDetails: 'Ver detalles',
    revokeCredential: 'Revocar credencial',
    resendEmail: 'Reenviar correo',
    ivmStatus: 'Estado de verificación de identidad',
    expirationDate: 'Fecha de expiración',
    rowsPerPage: 'Filas por página:',
    of: 'de',
    more: 'más de',
  },
  menu: {
    credentials: 'Gestión de credenciales',
    issueCredentials: 'Emitir credenciales',
    user: 'Gestión de usuario',
    auditTrails: 'Auditoría',
    credentialRequests: 'Solicitudes de credencial',
  },
  details: {
    attachments: 'Adjuntos',
    auditTrailsDetails: 'Detalles de la Auditoría',
    name: 'Nombre',
    eventName: 'Nombre del evento',
    operatorName: 'Nombre del agente',
    entity: 'Entidad',
    operator: 'Agente',
    role: 'Rol',
    legalId: 'Identificador legal',
    action: 'Acción',
    payload: 'Contenido',
    emailResentSuccess: 'El correo se ha reenviado con éxito',
    revokeSuccess: 'Credencial revocada con éxito',
    rejectSuccess: 'Solicitud de credencial rechazada',
    credentialDetails: 'Detalles de la credencial',
    userDetails: 'Detalles del usuario',
    requestDetails: 'Detalles de solicitud',
    credentialRequestDetails: 'Solicitud de credencial',
    credentialOrderId: 'ID de orden de credencial',
    credentialIssuanceId: 'ID de emisión de credencial',
    type: 'Tipo de credencial',
    types: 'Tipo',
    issuer: 'Emisor',
    holder: 'Poseedor',
    createdAt: 'Creada',
    issuedAt: 'Emitida',
    id: 'ID',
    test: 'Prueba',
    receiverEmail: 'Email del receptor',
    identityVerificationMethod: 'Método de verificación de identidad',
    faceToFace: 'Presencial',
    next: 'Siguiente',
    previous: 'Anterior',
    stillNotSigned:
      "El documento aún no ha sido firmado. Firma el documento en el dispositivo y haz clic en 'Siguiente'.",
    credentialIssuanceDocumentSignature:
      'Firma del documento de la solicitud de emisión de la credencial',
    selectDevice:
      'Selecciona un dispositivo al que enviar el documento a firmar:',
    signedDocument:
      'Se ha enviado un email al usuario para finalizar el proceso',
    docHasBeenSent:
      "Firma el documento en el dispositivo y haz clic en 'Siguiente'.",
    docHasBeenRejected: 'El documento ha sido rechazado',
    sent: 'Documento enviado',
    signed: 'Documento firmado',
    credentialOrderCreatedFromRequest:
      'Tu solicitud de credencial ha sido aprobada. Vaya al listado de credenciales.',
    credentialRequestApproved: 'Solicitud de credencial aprobada',
  },
  requestDetails: {
    credentialRequestId: 'ID de solicitud de credencial',
    type: 'Tipo de credencial',
    receiverEmail: 'Email del receptor',
    requestedAt: 'Solicitada',
    identityVerificationMethod: 'Método de verificación de identidad',
    F2F: 'Presencial',
    userDetails: 'Detalles del usuario',
    EmailCredential: 'Credencial de email',
    VidKycCredential: 'KYC',
  },
  identityVerificationMethod: {
    userName: 'Nombre y apellidos',
    userLegalId: 'Número de Identidad',
  },
  status: {
    CREATED: 'Creado',
    ISSUED: 'Emitido',
    REVOKED: 'Revocado',
    REVOCATION_REQUESTED: 'Revocación solicitada',
    undefined: 'Indefinido',
    REQUESTED: 'Solicitado',
    VERIFIED: 'Verificado',
    UNVERIFIED: 'No verificado',
    ISSUE_REQUESTED: 'Emisión solicitada',
    REVOCATION_FAILED: 'Revocación fallida',
  },
  requestStatus: {
    REQUESTED: 'Solicitado',
    APPROVED: 'Aprobada',
  },
  identityVerificationStatus: {
    CREATED: 'Creado',
    STARTED: 'Iniciado',
    DOCUMENT_REJECTED: 'Documento rechazado',
    DOCUMENT_SENT: 'Documento enviado',
    FINISHED: 'Finalizado',
  },
  auditTrailsActions: {
    UserLogin: 'Inicio de sesión',
    CredentialOrderCreated: 'Orden de credencial creada',
    ResendCredentialOrderEmail: 'Correo de solicitud de credencial reenviado',
    CredentialHolderAuthenticated: 'Receptor de la credencial autenticado ',
    CredentialIssued: 'Credencial emitida',
    CredentialRevocationRequested: 'Revocación de credencial solicitada',
    CredentialRevoked: 'Credencial revocada',
    CredentialRequestCreated: 'Solicitud de orden de credencial creada',
    CredentialOrderRequestCreated: 'Solicitud de orden de credencial creada',
    CredentialRequestConfirmed: 'Solicitud de credencial confirmada',
    CredentialRequestDocumentSigned:
      'Documento de la solicitud de credencial firmado',
    CredentialOrderRequestDeleted: 'Solicitud de credencial eliminada',
    CredentialMigrated: 'Credencial migrada',
    PresentationCreated: 'Presentación creada',
  },
};
