import React from 'react';
import {
  CredentialIssuedDetailsView,
  CredentialListView,
} from '../../../domain/credentialView';
import './CredentialDetailsModal.css';
import { CredentialOrderModal } from './CredentialOrderModal/CredentialOrderModal';
import { CredentialIssuedModal } from './CredentialIssuedModal/CredentialIssuedModal';
import { decodeJWT } from 'did-jwt';

interface Props {
  credential: any;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  projection: CredentialListView;
}

export const CredentialDetailsModal = ({
  credential,
  isOpen,
  setIsOpen,
  projection,
}: Props) => {
  if (!credential) {
    return <></>;
  }
  function parseJWT(credential: any) {
    if (
      credential.verifiableCredential &&
      'jwt' in credential.verifiableCredential
    ) {
      const { payload } = decodeJWT(credential.verifiableCredential.jwt);
      return { ...credential, verifiableCredential: payload };
    }
  }

  return (
    <>
      {projection.resendURL ? (
        <CredentialOrderModal
          credential={
            parseJWT(credential) ?? (credential as CredentialIssuedDetailsView)
          }
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      ) : (
        <CredentialIssuedModal
          credential={
            parseJWT(credential) ?? (credential as CredentialIssuedDetailsView)
          }
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      )}
    </>
  );
};
