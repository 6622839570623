import React, { useContext, useState } from 'react';
import './CredentialsTable.css';
import {
  getCredentialView,
  ParsedBackendError,
  resendEmailCredentialOrder,
  revokeVerifiableCredential,
} from '../../../services/vidcredentials';
import { CredentialDetailsModal } from '../CredentialDetailsModal/CredentialDetailsModal';
import {
  CredentialListView,
  CredentialOrderDetailsView,
} from '../../../domain/credentialView';
import eyeSelectedIcon from '../../../assets/eyeSelectedIcon.png';
import revokeIcon from '../../../assets/revokeIcon.png';
import resendIcon from '../../../assets/resendIcon.png';
import TooltipItem from '../../../components/ToolTipItem/ToolTipItem';
import MaterialTable from 'material-table';
import { Popup } from '../../../components/Popup/Popup';
import CustomPagination from '../../../components/TablePagination/CustomPagination';
import { AllCredentialsContext } from '../../../contexts/CredentialsProviders/Providers/AllCredentialsContext';
import { Spinner } from '../../../components/Spinner/Spinner';
import { formatDate } from '../CredentialDetailsModal/helpers/StringHelpers';
import { useTranslation } from 'react-i18next';

export function CredentialsTable() {
  const { t } = useTranslation();
  const { credentials, isLoading, tableSpecs, loadCredentials, filters } =
    useContext(AllCredentialsContext);
  const [isCredentialDetailsModalOpen, setCredentialDetailsModalOpen] =
    useState(false);

  const [credential, setCredential] = useState(
    {} as CredentialOrderDetailsView,
  );
  const [projection, setProjection] = useState({} as CredentialListView);
  const [requestedRevocation, setRequestedRevocation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [credentialToRevoke, setCredentialToRevoke] = useState<string | null>(
    null,
  );

  const openCredential = async (data: CredentialListView, e: any) => {
    e.preventDefault(); //Prevent the default href=# behavoir
    try {
      const credential = await getCredentialView(data.detailsURL);
      setCredential(credential as CredentialOrderDetailsView);
      setProjection(data);
      setCredentialDetailsModalOpen(true);
    } catch (error) {
      console.log('Error unable to get the credentialDetails');
      return;
    }
  };
  const revokeCredential = async (credentialId: string) => {
    try {
      setRequestedRevocation(true);
      await revokeVerifiableCredential(credentialId);
      setTimeout(() => loadCredentials(10, 1), 5000);
      setSuccessMessage(t('details.revokeSuccess'));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage((error as ParsedBackendError).title);
      setSuccessMessage('');
    }

    setCredentialToRevoke(null);
  };
  const resendEmail = async (resendEmailPath: string, e: any) => {
    try {
      e.preventDefault();
      await resendEmailCredentialOrder(resendEmailPath);
      setSuccessMessage(t('details.emailResentSuccess'));
      setErrorMessage('');
    } catch (error) {
      setErrorMessage((error as ParsedBackendError).title);
      setSuccessMessage('');
    }
  };

  const tableColumns = [
    {
      title: 'Email',
      field: 'email',
    },
    {
      title: 'DID',
      field: 'did',
      render: (data: CredentialListView) => {
        return (
          <>
            {!['CREATED', 'VERIFIED', 'UNVERIFIED'].includes(data.status) ? (
              <TooltipItem id={'did' + data.issuanceId} title={data.did}>
                <div className="divFixed">
                  <span>{data.did}</span>
                </div>
              </TooltipItem>
            ) : (
              t('table.notAvailable')
            )}
          </>
        );
      },
    },
    {
      title: t('filters.credentialType'),
      field: 'credentialType',
    },
    {
      title: t('table.creationDate'),
      field: 'creationDate',
      render: (data: CredentialListView) => formatDate(data.creationDate),
    },
    {
      title: t('table.status'),
      field: 'status',
      render: (data: CredentialListView) => t(`status.${data.status}`),
    },
    {
      title: '',
      field: '',
      render: (data: CredentialListView) => {
        return (
          <div className="icons-container">
            <button
              onClick={() =>
                setCredentialToRevoke(
                  data.revokeURL
                    ? data.revokeURL.split('/').slice(-1).join()
                    : null,
                )
              }
              disabled={data.status !== 'ISSUED' || requestedRevocation}
              className="imageButton"
            >
              <TooltipItem
                id={'revoke' + data.issuanceId}
                title={t('table.revokeCredential')}
                enabled={data.status === 'ISSUED'}
              >
                <img src={revokeIcon} />
              </TooltipItem>
            </button>{' '}
            <button // TODO: use the urls to check if the buttons should be enabled
              disabled={data.status !== 'VERIFIED'} //&& !data.resendURL
              className="imageButton"
              onClick={(e) => resendEmail(data.resendURL as string, e)}
            >
              <TooltipItem
                id={'resend' + data.issuanceId}
                title={t('table.resendEmail')}
              >
                <img src={resendIcon} />
              </TooltipItem>
            </button>
            <button
              className="imageButton"
              onClick={(e) => openCredential(data, e)}
            >
              <TooltipItem
                id={'detail' + data.issuanceId}
                title={t('table.viewDetails')}
              >
                <img src={eyeSelectedIcon} className="eyeIcon" />
              </TooltipItem>
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <CredentialDetailsModal
        isOpen={isCredentialDetailsModalOpen}
        setIsOpen={setCredentialDetailsModalOpen}
        credential={credential}
        projection={projection}
      />
      <MaterialTable
        key={tableSpecs.tableSize}
        columns={tableColumns}
        isLoading={isLoading}
        data={credentials}
        title={''}
        options={{
          selection: false,
          search: false,
          pageSize: tableSpecs.tableSize,
          headerStyle: {
            background: '#F9F9FB 0% 0% no-repeat padding-box',
            fontFamily: 'TT-Norms-Black',
          },
          maxBodyHeight: screen.height * 0.6,
          emptyRowsWhenPaging: false,
        }}
        components={{
          OverlayLoading: () => (
            <div
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.7)',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </div>
          ),
          Pagination: () => (
            <CustomPagination
              tableSpecs={tableSpecs}
              loadCredentials={loadCredentials}
              filters={filters}
            />
          ),
        }}
      />
      {errorMessage && (
        <Popup
          error
          message={errorMessage}
          onClose={() => setErrorMessage('')}
        />
      )}
      {successMessage && (
        <Popup
          success
          message={successMessage}
          onClose={() => setSuccessMessage('')}
        />
      )}
      {credentialToRevoke && (
        <Popup
          confirmation
          message={t('table.sureRevoke')}
          onConfirm={() => revokeCredential(credentialToRevoke)}
          onReject={() => setCredentialToRevoke(null)}
          onClose={() => setCredentialToRevoke(null)}
        />
      )}
    </div>
  );
}
