import { createContext, PropsWithChildren, useState } from 'react';

export const AuthContext = createContext({
  accessToken: '',
  setToken: (_accessToken: string) => {
    return;
  },
});

export const AuthContextProvider = ({ children }: PropsWithChildren<any>) => {
  const [accessToken, setToken] = useState<string>('');

  const { Provider } = AuthContext;

  return (
    <Provider
      value={{
        accessToken,
        setToken,
      }}
    >
      {children}
    </Provider>
  );
};
