import i18n from '../../../../i18n/i18n';

const isIsoDate = (str: any): boolean => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const date = new Date(str);
  return !Number.isNaN(date) && date.toISOString() === str;
};

const formatDate = (date: string | undefined): string => {
  if (!date) {
    return i18n.t('table.notAvailable');
  }
  const formattedDate = new Date(date);
  return formattedDate.toLocaleString();
};

const transformCamelCase = function (str: string) {
  const cleanedStr = str
    .replace('ID', 'Id')
    .replace('@', '')
    .match(/^[a-zà-ú]+|[A-ZÀ-Ú][\']?[a-zà-ú]*/g);
  if (!cleanedStr) {
    return '';
  }
  return cleanedStr
    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const convertToQueryString = (convertableObject: Record<any, any>): string =>
  Object.entries(convertableObject)
    .filter(([_, value]) => value)
    .map(([key, value]) => {
      if (key.includes('Date')) {
        return [key, new Date(value).toISOString()];
      }
      return [key, value];
    })
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

export { formatDate, isIsoDate, transformCamelCase, convertToQueryString };
