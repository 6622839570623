import { CredentialDetailText } from '../components/CredentialDetailText';
import { CredentialDetailList } from '../components/CredentialDetailList';

const IMAGE_URL_REGEX = /(http(s?):)*\.(?:jpg|gif|png)/g;

type RenderChildrenOptions = {
  level?: number;
  isExpanded?: boolean;
};

function renderChildren(
  credentialDetail: any,
  { level, isExpanded }: RenderChildrenOptions,
) {
  if (isExpanded === false) {
    return null;
  }
  if (
    typeof credentialDetail !== 'string' &&
    typeof credentialDetail !== 'number' &&
    typeof credentialDetail !== 'boolean'
  ) {
    return (
      <CredentialDetailList
        credentialDetail={credentialDetail}
        level={level !== undefined ? level + 1 : 0}
      />
    );
  }
  if (credentialDetail.toString().match(IMAGE_URL_REGEX)) {
    return (
      <img
        height={100}
        width={100}
        src={credentialDetail.toString()}
        alt="Credential Image"
      />
    );
  }

  return <CredentialDetailText text={credentialDetail.toString()} />;
}

export { renderChildren };
