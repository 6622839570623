import React, { Fragment, useContext, useState } from 'react';
import { Form, InputGroup } from 'reactstrap';
import { Input } from 'reactstrap';
import './SearchBar.css';
import advancedFilter from '../../assets/advancedFilter.svg';
import searchIcon from '../../assets/searchIcon.svg';
import { convertToQueryString } from '../../pages/Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { CredentialsContext } from '../../contexts/CredentialsProviders/CredentialsContext';
import FormController from '../../domain/FormBuilder/FormController';
import { useTranslation } from 'react-i18next';

interface SearchBarProps {
  context: React.Context<CredentialsContext<any, any>>;
}

export function SearchBar({ context }: SearchBarProps) {
  const { t } = useTranslation();
  const {
    advancedFilterModal,
    setAdvancedFilterModal,
    setFilters,
    filters,
    loadCredentials,
    tableSpecs,
  } = useContext(context);

  const [debounceTimeoutId, setDebounceTimeoutId] =
    useState<NodeJS.Timeout | null>(null);

  function debounceOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    // Type and only launch a request once the user has stopped typing (i.e., 1.5 seconds)
    if (debounceTimeoutId) {
      clearTimeout(debounceTimeoutId);
      setDebounceTimeoutId(null);
    }

    const updatedFilters = {
      ...filters,
      searchableText: event.target.value,
    };
    setFilters(updatedFilters);

    setDebounceTimeoutId(
      setTimeout(async () => {
        const queryParams = convertToQueryString(updatedFilters);
        await loadCredentials(tableSpecs.tableSize, 1, queryParams);
      }, 1500),
    );
  }

  return (
    <Fragment>
      {advancedFilterModal && <div className="black-layout"></div>}
      <Form
        onSubmit={(e) => e.preventDefault()}
        className={
          advancedFilterModal ? 'advanced-filter-credentials' : 'simple-filter'
        }
      >
        <div className="custom-searchbar">
          <InputGroup className="input-finder-search-bar">
            <img
              alt="Search icon"
              className="search-icon"
              src={searchIcon}
              onClick={() => setAdvancedFilterModal(true)}
            />
            <Input
              className="custom-searchbar-input"
              placeholder={t('actions.search')}
              value={filters.searchableText}
              onChange={debounceOnChange}
            />
            <div>
              <img
                alt="Advanced filter icon"
                className="img-advanced-filter"
                src={advancedFilter}
                onClick={() => setAdvancedFilterModal(true)}
              />
            </div>
          </InputGroup>
        </div>
        {advancedFilterModal && <FormController context={context} />}
      </Form>
    </Fragment>
  );
}
