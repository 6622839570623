import React, { useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import './CredentialTemplate.css';
import {
  FieldErrors,
  FieldValues,
  RegisterOptions,
  UseFormRegister,
} from 'react-hook-form';
import { Switch } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface Template {
  name: string;
  fieldType: string;
  optional?: boolean;
}

interface Props {
  credentialTypeId: string;
  template: Template[];
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
}

export const CredentialTemplate = ({
  template,
  register,
  errors,
  credentialTypeId,
}: Props) => {
  const { t } = useTranslation();
  const [switchsValues, setSwitchValue] = useState<{ [key: string]: boolean }>(
    {},
  );

  return (
    <FormGroup>
      <Label for="credentialSubject">Credential Subject</Label>
      {React.Children.toArray(
        template.map((field) => {
          if (
            field.fieldType === 'boolean' &&
            !Object.keys(switchsValues).includes(field.name)
          ) {
            setSwitchValue({ ...switchsValues, [field.name]: false });
          }

          const isDateFieldType = field.fieldType === 'date';
          const isBooleanType = field.fieldType === 'boolean';
          const isOptional = !!field.optional;

          let registerOptions: RegisterOptions = {
            required: t('form.fillfield'),
          };
          if (field.name === 'email') {
            registerOptions = {
              required: t('form.fillfield'),
              pattern: {
                value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                message: t('form.provideValidEmail'),
              },
            };
          }

          if (field.name === 'urlField') {
            registerOptions = {
              required: t('form.fillfield'),
              pattern: {
                value:
                  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/,
                message: t('form.provideValidURL'),
              },
            };
          }

          if (field.name === 'numberField') {
            registerOptions = {
              required: t('form.fillfield'),
              pattern: {
                value: /^(0|[1-9][0-9]*)$/,
                message: t('form.provideValidNumber'),
              },
            };
          }

          if (isDateFieldType) {
            registerOptions = {
              required: t('form.provideValidDate'),
            };
          }

          if (isOptional || isBooleanType) {
            registerOptions = {
              required: false,
            };
          }

          const { ref, ...rest } = register(field.name, registerOptions);

          return (
            <>
              {(isDateFieldType || isBooleanType) && (
                <>
                  <br /> <label>{field.name}</label>{' '}
                </>
              )}

              {isBooleanType ? (
                <Switch
                  key={field.name}
                  id={`${credentialTypeId}-${field.name}`}
                  ref={ref}
                  value={switchsValues[field.name]}
                  name={field.name}
                  onChange={() =>
                    setSwitchValue({
                      ...switchsValues,
                      [field.name]: !switchsValues[field.name],
                    })
                  }
                  color="primary"
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              ) : (
                <Input
                  type={isDateFieldType ? 'date' : 'text'}
                  key={field.name}
                  className="templateField"
                  id={`${credentialTypeId}-${field.name}`}
                  placeholder={field.name}
                  innerRef={ref}
                  {...rest}
                />
              )}
              {errors[field.name] && (
                <span className="error-span">
                  {errors[field.name]?.message}
                </span>
              )}
            </>
          );
        }),
      )}
    </FormGroup>
  );
};
