import { LabelDisplayedRowsArgs, TablePagination } from '@mui/material';
import { TableSpecs } from '../../pages/Credentials/Credentials';
import { CredentialsFilters } from '../../contexts/CredentialsProviders/Providers/AllCredentialsContext';

import { convertToQueryString } from '../../pages/Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { UserCredentialsFilters } from '../../contexts/CredentialsProviders/Providers/UserCredentialsContext';
import { useTranslation } from 'react-i18next';

interface CustomPaginationProps {
  loadCredentials: (
    tableSize: number,
    page: number,
    filterQuery?: string,
  ) => Promise<void>;
  tableSpecs: TableSpecs;
  filters?: CredentialsFilters | Partial<UserCredentialsFilters>;
}

export default function CustomPagination({
  loadCredentials,
  // TODO: use tableSpecs from context
  tableSpecs,
  filters,
}: CustomPaginationProps) {
  const { t } = useTranslation();
  const queryParams = filters ? convertToQueryString(filters) : undefined;
  const { totalNumber, tableSize, currentPage } = tableSpecs;

  function defaultLabelDisplayedRows({
    from,
    to,
    count,
  }: LabelDisplayedRowsArgs) {
    return `${from}–${to} ${t('table.of')} ${
      count !== -1 ? count : `${t('table.more')} ${to}`
    }`;
  }

  return (
    <TablePagination
      style={{ justifyContent: 'flex-end', display: 'flex' }}
      labelRowsPerPage={t('table.rowsPerPage')}
      labelDisplayedRows={defaultLabelDisplayedRows}
      className="paginationContainer"
      count={totalNumber}
      page={currentPage - 1}
      rowsPerPage={tableSize}
      onPageChange={async (_, nextPage) => {
        await loadCredentials(tableSize, nextPage + 1, queryParams);
      }}
      onRowsPerPageChange={async (event) => {
        const nextRowPerPage = +event.target.value;
        const nextCurrentPage = Math.ceil(
          (currentPage * tableSize) / nextRowPerPage,
        );
        const isOutOfBoundaries =
          nextCurrentPage * nextRowPerPage > totalNumber;
        const lastPage = Math.ceil(totalNumber / nextRowPerPage);
        const adaptedCurrentPage = isOutOfBoundaries
          ? lastPage
          : nextCurrentPage;

        await loadCredentials(
          +event.target.value,
          adaptedCurrentPage,
          queryParams,
        );
      }}
      showFirstButton
      showLastButton
    />
  );
}
