import i18n from '../i18n/i18n';

const FrontendErrorCodes = {
  LOGIN_AUTH: i18n.t('frontend_error_000'),
  CREDENTIAL_ORDER: i18n.t('frontend_error_001'),
  CREDENTIAL_LIST: i18n.t('frontend_error_002'),
  ISSUE_CREDENTIAL: i18n.t('frontend_error_003'),
  CREDENTIAL_TYPES: i18n.t('frontend_error_004'),
  LEVEL_OF_ASSURANCES: i18n.t('frontend_error_005'),
  LEVEL_OF_ASSURANCE_TYPE: i18n.t('frontend_error_006'),
  GET_ENTITIES: i18n.t('frontend_error_007'),
  RESEND_EMAIL: i18n.t('frontend_error_008'),
  CREDENTIAL_REQUEST: i18n.t('frontend_error_009'),
};

const FrontendErrors = {
  LOGIN_AUTH: FrontendErrorCodes.LOGIN_AUTH,
  CREDENTIAL_ORDER: FrontendErrorCodes.CREDENTIAL_ORDER,
  CREDENTIAL_LIST: FrontendErrorCodes.CREDENTIAL_LIST,
  ISSUE_CREDENTIAL: FrontendErrorCodes.ISSUE_CREDENTIAL,
  CREDENTIAL_TYPES: FrontendErrorCodes.CREDENTIAL_TYPES,
  LEVEL_OF_ASSURANCES: FrontendErrorCodes.LEVEL_OF_ASSURANCES,
  LEVEL_OF_ASSURANCE_TYPE: FrontendErrorCodes.LEVEL_OF_ASSURANCE_TYPE,
  GET_ENTITIES: FrontendErrorCodes.GET_ENTITIES,
  RESEND_EMAIL: FrontendErrorCodes.RESEND_EMAIL,
  CREDENTIAL_REQUEST: FrontendErrorCodes.CREDENTIAL_REQUEST,
};

export { FrontendErrors };
