import { createContext, ReactChild, useState } from 'react';

export const MenuContext = createContext({
  isMenuOpen: true,
  setIsOpen: (_isOpen: boolean) => {
    return;
  },
});

interface Props {
  children: ReactChild;
}

export const MenuContextProvider = (props: Props) => {
  const [isMenuOpen, setIsOpen] = useState(true);

  const { children } = props;

  const Provider = MenuContext.Provider;

  return (
    <Provider
      value={{
        isMenuOpen,
        setIsOpen,
      }}
    >
      {children}
    </Provider>
  );
};
