import React, { useContext, useEffect } from 'react';
import { Container } from 'reactstrap';
import { MenuContext } from '../../contexts/MenuContext';
import { useRefreshToken } from '../../hooks/useRefreshToken';
import refreshIcon from '../../assets/refreshIcon.svg';
import FilterTag from '../../components/FilterTag/FilterTag';
import { CredentialRequestsContext } from '../../contexts/CredentialsProviders/Providers/CredentialRequestsContext';
import { CredentialRequestsTable } from './CredentialRequestsTable/CredentialRequestsTable';
import { useRouteSecurization } from '../../hooks/useRouteSecurization';
import { CredentialsFilters } from '../../contexts/CredentialsProviders/Providers/AllCredentialsContext';
import { convertToQueryString } from '../Credentials/CredentialDetailsModal/helpers/StringHelpers';
import { SearchBar } from '../../components/SearchBar/SearchBar';
import { useTranslation } from 'react-i18next';

export function CredentialRequests() {
  const { t } = useTranslation();
  const {
    loadSelectCredentialTypes,
    filters,
    loadCredentials,
    tableSpecs,
    setFilters,
  } = useContext(CredentialRequestsContext);
  const { email, types, searchableText, status } = filters;
  const { isMenuOpen } = useContext(MenuContext);

  useRouteSecurization(['admin', 'operator']);

  const onRemoveTag = async (filterKey: keyof CredentialsFilters) => {
    const updatedFilter = { ...filters, [filterKey]: '' };
    const queryParams = convertToQueryString(updatedFilter);
    setFilters(updatedFilter);
    await loadCredentials(tableSpecs.tableSize, 1, queryParams);
  };

  const refreshData = async () => {
    const queryParams = convertToQueryString(filters);
    await loadCredentials(
      tableSpecs.tableSize,
      tableSpecs.currentPage,
      queryParams,
    );
  };

  const getInitialData = () => {
    loadCredentials(10, 1);
    loadSelectCredentialTypes();
  };

  const tokenProcess = useRefreshToken(getInitialData);

  useEffect(() => {
    tokenProcess.refreshTokenProcess();
  }, []);

  return (
    <Container
      className={`credentials-container ${
        isMenuOpen ? 'open-lateral-menu' : ''
      }`}
      fluid
    >
      <h1>{t('menu.credentialRequests')}</h1>
      <br></br>
      <SearchBar context={CredentialRequestsContext} />
      <div className="filter-list-container">
        {types && (
          <FilterTag
            filter={`${t('details.types')}: ${types}`}
            onClose={() => onRemoveTag('types')}
          />
        )}
        {email && (
          <FilterTag
            filter={`Email: ${email}`}
            onClose={() => onRemoveTag('email')}
          />
        )}
        {searchableText && (
          <FilterTag
            filter={`${t('filters.searchText')}: ${searchableText}`}
            onClose={() => onRemoveTag('searchableText')}
          />
        )}
        {status && (
          <FilterTag
            filter={`${t('table.status')}: ${t(`requestStatus.${status}`)}`}
            onClose={() => onRemoveTag('status')}
          />
        )}
      </div>
      <>
        <div className="refresh-icon-container">
          <img
            onClick={refreshData}
            src={refreshIcon}
            alt="Refresh icon"
            className="refresh-button"
          />
        </div>
        <CredentialRequestsTable />
      </>
    </Container>
  );
}
