import { Modal, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
import './Popup.css';
import closeIcon from '../../assets/closeIcon.png';
import { useTranslation } from 'react-i18next';

interface Props {
  message: string;
  error?: boolean;
  success?: boolean;
  confirmation?: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  onReject?: () => void;
}

export const Popup = (props: Props) => {
  const { t } = useTranslation();
  const {
    message,
    error,
    success,
    onClose,
    confirmation,
    onConfirm,
    onReject,
  } = props;

  return (
    <Modal isOpen={true} centered={true} fullscreen="lg" size="lg">
      <ModalHeader
        close={
          <div onClick={onClose}>
            <img src={closeIcon} className="iconClose" />
          </div>
        }
        className="modalHeader"
      >
        {success && t('actions.success')}
        {confirmation && t('actions.confirm')}
        {error && 'Error'}
      </ModalHeader>
      <ModalBody className="modalBody">{message}</ModalBody>
      <ModalFooter>
        {confirmation ? (
          <>
            <Button className="buttonResend" onClick={onConfirm}>
              {t('actions.accept')}
            </Button>
            <Button className="buttonResend" onClick={onReject}>
              {t('actions.cancel')}
            </Button>
          </>
        ) : (
          <Button className="buttonResend" onClick={onClose}>
            {t('actions.accept')}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
